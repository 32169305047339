<template>
  <div>
    <div>
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="list.beginDate"
            align="right"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="SearchPageData"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <button class="centerCzbtn" type="button" @click="getTableDate">
            搜索
          </button>
        </el-form-item>
      </el-form>
    </div>
    <div class="graphical mb20">
      <el-col :span="5">
        <div class="deforDv">
          <p class="grapwords">园区数据通信</p>

          <div class="circleBox">
            <el-progress
              type="circle"
              :percentage="titList.parkDataUploadRate"
              :show-text="false"
              :stroke-width="10"
              :width="140"
              color="#5693CC"
            >
            </el-progress>
            <div class="el-progress__text">
              <p>
                {{ titList.parkDataComm.uploadNum }}/{{ titList.parkDataComm.parkTotalNum }}
              </p>
              <p>上传数据园区比例</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="deforDv">
          <p class="grapwords">各园区车辆占比</p>
          <div class="echarts">
            <div
              id="myChart"
              :style="{ width: '162px', height: '140px' }"
            ></div>
            <div class="carList">
              <div class="carHead">
                总车辆数
                <span class="carHeadcolor">{{
                  titList.parkCarRate.carTotal
                }}</span>
                <span class="carbold">(辆)</span>
              </div>
              <div
                class="carLi"
                v-for="(item, index) in titList.parkCarRate.parkList"
                :key="index"
              >
                <div class="carLiLeft">
                  <div class="carBg1" v-if="index == 0"></div>
                  <div class="carBg2" v-else-if="index == 1"></div>
                  <div class="carBg3" v-else-if="index == 2"></div>
                  <div class="carBg4" v-else-if="index == 3"></div>
                  <span>{{ item.parkTitle }}</span>
                </div>
                <div class="carbold">{{ item.carNum }}</div>
              </div>

              <!-- <div class="carLi">
                <div class="carLiLeft">
                  <div class="carBg2"></div>
                  <span>青岛海洋实验室</span>
                </div>
                <div class="carbold">13</div>
              </div>

              <div class="carLi">
                <div class="carLiLeft">
                  <div class="carBg3"></div>
                  <span>北京房山园区</span>
                </div>
                <div class="carbold">10</div>
              </div>

              <div class="carLi">
                <div class="carLiLeft">
                  <div class="carBg4"></div>
                  <span>汽车基地园区</span>
                </div>
                <div class="carbold">8</div>
              </div> -->
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="graphicalRight">
          <el-col :span="12">
            <div class="rightword">当日接受数据</div>
            <div class="rightBold">{{ titList.todayReceive }}</div>
          </el-col>
          <el-col :span="12">
            <div class="rightword">近30天最大接收数据</div>
            <div class="rightBold">{{ titList.totalReceivedData }}</div>
          </el-col>
          <el-col :span="12" class="mt20">
            <div class="rightword">总接收数据（近6个月）</div>
            <div class="rightBold">{{ titList.maxReceivedData }}</div>
          </el-col>
        </div>
      </el-col>
    </div>

    <Table
      :data1="tableData"
      :columns1="columns"
      :total="total"
      @getAjax="getTableDate"
      :loading="loading"
    />

    <!-- <router-view /> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import Table from "../../components/Table.vue";
import { _dataMngList, _dataMngTit } from "@/api/dataMng";

export default {
  components: {
    Table,
  },
  data() {
    return {
      date: "",
      loading: false,
      total: 0,
      tableData: [],
      colorlist: ["#5693cc", "#6282e5", "#7d62e5", " #c26cb6"], //扇形图颜色
      columns: [
        {
          label: "园区名称",
          prop: "parkName",
          width: 200,
        },
        {
          label: "注册时间",
          prop: "regDate",
          width: 200,
        },
        {
          label: "车辆数",
          prop: "carNum",
        },
        {
          label: "最后一次上传时间(设备数据)",
          prop: "lastUploadDeviceDate",
          width: 200,
        },
        {
          label: "条数",
          prop: "deviceDataNum",
        },
        {
          label: "最后一次上传时间(业务数据)",
          width: 170,
          prop: "lastUploadBusinessDate",
        },
        {
          label: "条数",
          prop: "businessDataNum",
        },
        {
          label: "操作",
          // fixed: "right",
          render: (h, params, index) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.$router.replace({
                        path: "/dataManagementDetail",
                        // query: { Index: params.row.parkName},
                      });
                      window.sessionStorage.setItem(
                        "parkName",
                        params.row.parkName
                      );
                    },
                  },
                },
                "查看详情"
              ),
            ]);
          },
        },
      ],
      list: {
        beginDate: "",
        endDate: "",
      },
      titList: {
        parkDataComm: [],
        parkCarRate: [],
        maxReceivedData: "",
        todayReceive: "",
        totalReceivedData: "",
				parkDataUploadRate: 0,
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.initPage();
  },
  methods: {
    async initPage() {
      await this.getTableDate();
      await this.getTitMng();
      this.drawLine();
    },

    //获取分页数据
    async getTableDate(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.page.currentPage = pagination.page;
          this.page.pageSize = pagination.limit;
        }
      }
      window.sessionStorage.setItem("dataMngpage", this.page.currentPage);
      window.sessionStorage.setItem("dataMngsize", this.page.pageSize);
			
      if (this.list.beginDate == null) {
        this.list.beginDate = "";
      }
			
      _dataMngList(this.list).then((res) => {
        if (res.success == true) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
        }
        this.loading = false;
      });
    },
    //获取统计数据信息
    async getTitMng() {
      if (this.list.beginDate == null) {
        this.list.beginDate = "";
      }
      let res = await _dataMngTit(this.list);
      if (res.success) {
        this.titList.parkDataComm = res.data.parkDataComm;
        this.titList.parkCarRate = res.data.parkCarRate;
        this.titList.maxReceivedData = res.data.maxReceivedData;
        this.titList.todayReceive = res.data.todayReceive;
        this.titList.totalReceivedData = res.data.totalReceivedData;
	
				if (this.titList.parkDataComm.parkTotalNum === 0) {
					this.titList.parkDataUploadRate = 0;
				}else{
					this.titList.parkDataUploadRate = (this.titList.parkDataComm.uploadNum / this.titList.parkDataComm.parkTotalNum) * 100;
				}
      }
      this.loading = false;
    },
    //加载扇形图信息
    drawLine() {
      let that = this;
      let myChart = echarts.init(document.getElementById("myChart"));
      let data = [];
      for (var a = 0; a < this.titList.parkCarRate.parkList.length; a++) {
        data.push({ value: this.titList.parkCarRate.parkList[a].carNum });
      }
      myChart.setOption({
        backgroundColor: "white",
        title: {
          text: "",
          left: "center",
          top: 20,
          textStyle: {
            color: "#ccc",
          },
        },

        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "100%",
            center: ["50%", "50%"],
            data: data.sort(function (a, b) {
              return a.value - b.value;
            }),
            roseType: "radius",

            labelLine: {
              lineStyle: {
                color: "white",
              },
              smooth: 0.2,
              length: 10,
              length2: 20,
            },

            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  let colorList = ["#5693cc", "#6282e5", "#7d62e5", " #c26cb6"];
                  return colorList[params.dataIndex];
                },
              },
            },

            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      });
    },
    //搜索
    async SearchPageData(val) {
      await this.getTableDate();
      await this.getTitMng();
    },
  },
};
</script>

<style  scoped>
/deep/.el-input__inner {
  border: 0px;
}
.graphical {
  height: 220px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
}

.deforDv {
  position: relative;
  /* left: 30px; */
  margin-left: 30px;
  text-align: center;
}

.grapwords {
  font-size: 16px;
  color: #666666;
  text-align: left;
}

.deforDv::before {
  content: "";
  width: 1px;
  height: 100px;
  background-color: #d4d7d9;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.circleBox {
  position: relative;
}

.el-progress__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-left: 0px;
}

.el-progress__text > p:first-child {
  font-size: 16px;
  font-weight: bold;
}

.el-progress__text > p:last-child {
  font-size: 12px;
  margin-top: 8px;
}

.echarts {
  width: 70%;
  margin: 0px auto;
  display: flex;
  justify-content: space-around;
}

.carList {
  font-size: 12px;
  color: #909090;
  width: 150px;
}

.carbold {
  color: #0f0f0f;
  font-weight: 500;
}

.carBg1 {
  width: 8px;
  height: 8px;
  background: #5693cc;
  border-radius: 50%;
}

.carBg2 {
  width: 8px;
  height: 8px;
  background: #6282e5;
  border-radius: 50%;
}

.carBg3 {
  width: 8px;
  height: 8px;
  background: #7d62e5;
  border-radius: 50%;
}

.carBg4 {
  width: 8px;
  height: 8px;
  background: #c26cb6;
  border-radius: 50%;
}

.carLi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.carHead {
  text-align: left;
}

.carHeadcolor {
  color: #1683d9;
}

.carLiLeft {
  display: flex;
  align-items: center;
}

.carLiLeft > span {
  margin-left: 10px;
}

.graphicalRight {
  padding: 40px 60px;
  color: #666666;
  font-size: 14px;
}

.rightword {
  font-size: 14px;
  color: #666;
}

.rightBold {
  color: #333333;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 600;
}
</style>
