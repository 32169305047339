import service from '@/until/api'

export function _dataMngList(data) {
    return service({
        url: '/report-data/dataMgt/parkList/' + JSON.parse(sessionStorage.getItem("dataMngsize")) + "/" + JSON.parse(sessionStorage.getItem("dataMngpage")),
        method: 'POST',
        data
    })
}
export function _dataMngTit(data) {
    return service({
        url: '/report-data/dataMgt/dataMgtStatistics',
        method: 'POST',
        data
    })
}
